import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import Sidebar from "../components/shared/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleCompany, updateCompany } from "../store/slices/companies";
import {
  fetchSingleJobSeeker,
  updateJobSeeker,
} from "../store/slices/jobSeeker";
import { fetchNotifications } from "../store/slices/notifications";
import { setRunTour, setStepIndex } from "../store/slices/joyride";
import { jwtDecode } from "jwt-decode";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { toast } from "react-toastify";

const UserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const job_seeker = useSelector((state) => state.jobSeeker.job_seeker);
  const company = useSelector((state) => state.company.company.company);

  const decodedToken = token && jwtDecode(token);

  // Joy Ride State
  const runTour = useSelector((state) => state.joyRide.runTour);
  const stepIndex = useSelector((state) => state.joyRide.stepIndex);

  const steps = [
    {
      target: ".__joyride_initial_step",
      content: "welcome to GSC Matchpoint! let's set you up in 5min!",
      placement: "center",
      hideCloseButton: true,
    },
    {},

    {
      target: ".__joyride_update_profile",
      content:
        "Kindly complete your profile and click the (Save Button), then click NEXT",
      hideCloseButton: true,
      placement: "top-center",
    },
    {
      target: ".__joyride_update_profile",
      content:
        "Kindly complete your profile and click the (Save Button), then click NEXT",
      placement: "top-center",
      hideCloseButton: true,
    },

    {},

    {
      target: ".__joyride_upload_docs",
      content: `Kindly upload necessary documents!`,
      hideCloseButton: true,
    },
    {
      target: ".__joyride_upload_docs",
      content: `Kindly upload necessary documents!`,
      hideCloseButton: true,
    },

    {},
    {
      target: ".__joyride_membership",
      content: `Kindly pick a subscription plan that best meet your needs!`,
      hideCloseButton: true,
    },
    {
      target: ".__joyride_settings",
      content: `Configure Settings (Subscriptions, preferences, update password etc...) Close the modal!`,
    },
    // Add more steps as needed
  ];

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if (
      [STATUS.FINISHED, STATUS.SKIPPED || action === ACTIONS.CLOSE].includes(
        status
      )
    ) {
      // dispatch an action to set the runTour state to false on the db
      //  when the status is finished or skipped
      if (decodedToken.user_type === "company") {
        dispatch(
          updateCompany({
            value: { has_run_tour: true },
            id: decodedToken.user_id,
          })
        );
      } else {
        dispatch(
          updateJobSeeker({
            value: { has_run_tour: true },
            id: decodedToken.user_id,
          })
        );
      }
      dispatch(setRunTour(false));
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (index === 2) {
        navigate("/user/details");
      } else if (index === 3) {
        if (decodedToken.user_type === "company") {
          if (company.is_profile_complete === false) {
            toast.error("Please complete your profile before proceeding");
            return;
          }
        } else {
          if (job_seeker.is_profile_complete === false) {
            toast.error("Please complete your profile before proceeding");
            return;
          }
        }
        navigate("/user/documents");
      } else if (index === 5 && decodedToken.user_type === "company") {
        navigate("/user/membership");
      }

      // Add a delay before setting the new step index
      setTimeout(() => {
        dispatch(setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1)));
      }, 500); // Delay of 1 second
    }
  };

  // Basically I'm checking if the user is logged in as an employer or a jobseeker
  // that will guild me on what data to fetch as soon as they log in
  // I say try to fetch notifications for the user
  useEffect(() => {
    if (decodedToken && decodedToken.user_type === "company") {
      dispatch(fetchSingleCompany(decodedToken.user_id));
      dispatch(fetchNotifications({ company_id: decodedToken.user_id }));
    } else {
      dispatch(fetchSingleJobSeeker(decodedToken.user_id));
      dispatch(fetchNotifications({ jobseeker_id: decodedToken.user_id }));
    }
  }, [decodedToken.user_id]);

  useEffect(() => {
    if (
      (job_seeker && job_seeker.has_run_tour === false) ||
      (company && company.has_run_tour === false)
    ) {
      dispatch(setRunTour(true));
    } else {
      dispatch(setRunTour(false));
    }
  }, [job_seeker?.has_run_tour, company?.has_run_tour]);

  return (
    <div className="w-full overflow-hidden bg-neutral-100 flex flex-row pt-32 pb-40 font-raleway max-sm:w-full">
      <Sidebar />
      <div className="flex-1 w-full max-sm:overflow-x-scroll max-sm:w-full">
        <div className="__joyride_initial_step max-sm:w-full">{<Outlet />}</div>
      </div>
      <Joyride
        steps={steps}
        run={runTour}
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
        continuous={true}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        spotlightClicks={true}
        spotlightPadding={10}
        disableScrolling={true}
      />
    </div>
  );
};

export default UserProfile;
